exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-activation-tsx": () => import("./../../../src/pages/terms/activation.tsx" /* webpackChunkName: "component---src-pages-terms-activation-tsx" */),
  "component---src-pages-terms-card-tsx": () => import("./../../../src/pages/terms/card.tsx" /* webpackChunkName: "component---src-pages-terms-card-tsx" */),
  "component---src-pages-terms-loan-tsx": () => import("./../../../src/pages/terms/loan.tsx" /* webpackChunkName: "component---src-pages-terms-loan-tsx" */),
  "component---src-pages-terms-mobile-tsx": () => import("./../../../src/pages/terms/mobile.tsx" /* webpackChunkName: "component---src-pages-terms-mobile-tsx" */),
  "component---src-pages-terms-saving-tsx": () => import("./../../../src/pages/terms/saving.tsx" /* webpackChunkName: "component---src-pages-terms-saving-tsx" */),
  "component---src-pages-terms-td-tsx": () => import("./../../../src/pages/terms/td.tsx" /* webpackChunkName: "component---src-pages-terms-td-tsx" */),
  "component---src-pages-terms-tuition-tsx": () => import("./../../../src/pages/terms/tuition.tsx" /* webpackChunkName: "component---src-pages-terms-tuition-tsx" */)
}

